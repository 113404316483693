export const roundOffNumber = number => {
  return parseFloat(number)?.toFixed(2);
};

export const euroConverter = number => {
  return `€${roundOffNumber(number)}`;
};

export const calculateVat = (grandTotal, vat) => {
  if (vat.gstType === 'inclusive') {
    const totalAmount = (grandTotal / (100 + vat.vat)) * 100;
    const vatAmount = grandTotal - totalAmount;
    return vatAmount;
  }

  const vatAmount = (grandTotal * vat.vat) / 100;
  return vatAmount;
};

export const subTotalAmount = (grandTotal, vat) => {
  if (vat.gstType === 'inclusive') {
    const grandTotalAmount = grandTotal;
    const totalAmount = (grandTotalAmount / (100 + vat.vat)) * 100;
    return totalAmount;
  }

  return grandTotal;
};

export const grandTotalAmount = (grandTotal, vat) => {
  if (vat.gstType === 'inclusive') {
    return grandTotal;
  }
  const totalAmountData = grandTotal;
  const vatAmount = (totalAmountData * vat.vat) / 100;
  const grandTotalAmountData = totalAmountData + vatAmount;
  return grandTotalAmountData;
};
