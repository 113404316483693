import React from 'react';
import logo from '../../assets/images/bt_icon.svg';
import './_header.scss';

const Header = () => {
  return (
    <>
      <div className="header-container">
        <div className="logo">
          <img src={logo} className="logo-img" height="40" width="43" alt="logo" />
        </div>
        <div className="title">Barbuddie</div>
      </div>
    </>
  );
};

export default Header;
