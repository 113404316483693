import { ORDER_REDUX_CONSTANTS } from './orderReduxConstants';

const initialOrder = {
  catalogueList: [],
  orderList: [],
  tax: 0,
  grandTotal: 0,
  finalQuantity: 0,
  isLoading: true,
  isOrderConfirmed: true,
  pageNotFound: false,
  tableNUmberList: [],
};

export const order = (state = initialOrder, action) => {
  switch (action.type) {
    case ORDER_REDUX_CONSTANTS.REQUEST_CATALOGUE_LIST:
      return {
        ...state,
        isLoading: true,
      };

    case ORDER_REDUX_CONSTANTS.GET_CATALOGUE: {
      let catalogueList = [...action.data];
      let { grandTotal } = state;
      let { finalQuantity } = state;
      const orderList = [...state?.orderList];
      catalogueList = catalogueList.map(category => ({
        ...category,
        items: category.items.map(item => {
          if (orderList.length !== 0) {
            const isExistingItem = orderList.find(existingOrder => existingOrder._id === item._id);
            return isExistingItem
              ? { ...item, quantity: isExistingItem.quantity }
              : { ...item, quantity: 0 };
          }
          return { ...item, quantity: 0 };
        }),
      }));

      if (orderList.length !== 0) {
        orderList.forEach(existingOne => {
          grandTotal += existingOne.price * existingOne.quantity;
          finalQuantity += existingOne.quantity;
        });
      }

      return {
        ...state,
        catalogueList,
        grandTotal,
        finalQuantity,
        isLoading: false,
        pageNotFound: false,
      };
    }

    case ORDER_REDUX_CONSTANTS.FAIL_GET_CATALOGUE_LIST:
      return {
        ...state,
        isLoading: false,
        pageNotFound: true,
      };

    case ORDER_REDUX_CONSTANTS.REQUEST_PENDING_ORDER_LIST:
      return {
        ...state,
        isLoading: true,
      };

    case ORDER_REDUX_CONSTANTS.GET_PENDING_ORDER_LIST:
      return {
        ...state,
        orderList: action.data,
        isLoading: false,
      };

    case ORDER_REDUX_CONSTANTS.FAIL_PENDING_ORDER_LIST:
      return {
        ...state,
        isLoading: false,
        pageNotFound: true,
      };

    case ORDER_REDUX_CONSTANTS.GET_TAX: {
      return {
        ...state,
        tax: action.data,
      };
    }

    case ORDER_REDUX_CONSTANTS.ADD_QUANTITY: {
      const catalogue = [...state.catalogueList];
      let total = state.grandTotal;
      let quantity = state.finalQuantity;
      const { item } = action;
      for (let i = 0; i < catalogue.length; i += 1) {
        if (catalogue[i]?._id === item.parentCategoryId) {
          if (catalogue?.[i]?.items?.length !== 0) {
            for (let j = 0; j < catalogue?.[i]?.items.length; j += 1) {
              if (catalogue?.[i]?.items?.[j]?._id === item._id) {
                catalogue[i].items[j].quantity += 1;
                total += item.price;
                quantity = +1;
                break;
              }
            }
          }

          break;
        }
      }
      return {
        ...state,
        catalogueList: [...catalogue],
        grandTotal: total,
        finalQuantity: quantity,
      };
    }

    case ORDER_REDUX_CONSTANTS.DECREASE_QUANTITY: {
      const catalogue = [...state.catalogueList];
      let total = state.grandTotal;
      let quantity = state.finalQuantity;
      const { item } = action;

      for (let i = 0; i < catalogue.length; i += 1) {
        if (catalogue[i]?._id === item.parentCategoryId) {
          if (catalogue?.[i]?.items?.length > 0) {
            for (let j = 0; j < catalogue?.[i]?.items.length; j += 1) {
              if (catalogue?.[i]?.items?.[j]?._id === item._id) {
                catalogue[i].items[j].quantity -= 1;
                total -= item.price;
                quantity -= 1;
                break;
              }
            }
          }
        }
      }
      return {
        ...state,
        catalogueList: [...catalogue],
        grandTotal: total,
        finalQuantity: quantity,
      };
    }

    case ORDER_REDUX_CONSTANTS.DRAFT_ORDER_LIST: {
      const { orderList } = action;
      const emptyOrderIndex = orderList.findIndex(existingOrder => existingOrder.quantity === 0);
      if (emptyOrderIndex > -1) orderList.splice(emptyOrderIndex, 1);
      return {
        ...state,
        orderList,
      };
    }

    case ORDER_REDUX_CONSTANTS.UPDATE_ORDER_LIST: {
      const { orderList, deletedItem } = action;
      let { grandTotal, finalQuantity } = state;
      const catalogueList = [...state.catalogueList];

      grandTotal -= deletedItem.quantity * deletedItem.price;
      finalQuantity -= deletedItem.quantity;

      for (let i = 0; i < catalogueList.length; i += 1) {
        if (catalogueList[i]._id === deletedItem.parentCategoryId) {
          if (catalogueList[i].items.length > 0) {
            for (let j = 0; j < catalogueList[i].items.length; j += 1) {
              if (catalogueList[i].items[j]._id === deletedItem._id) {
                catalogueList[i].items[j].quantity = 0;
              }
            }
          }
        }
      }
      return {
        ...state,
        catalogueList,
        orderList,
        grandTotal,
        finalQuantity,
      };
    }

    case ORDER_REDUX_CONSTANTS.ADD_FAVORITE: {
      const catalogueList = [...state.catalogueList];
      const { item, isFavoriteSuccessfully } = action;

      for (let i = 0; i < catalogueList.length; i += 1) {
        if (catalogueList?.[i]?._id === item.parentCategoryId) {
          if (catalogueList[i].items.length > 0) {
            for (let j = 0; j < catalogueList[i].items.length; j += 1) {
              if (catalogueList?.[i]?.items[j]?._id === item._id) {
                if (item.isFavoriteSuccessfully) {
                  catalogueList[i].items[j] = {
                    ...catalogueList[i].items[j],
                    isFavorite: true,
                  };
                } else {
                  catalogueList[i].items[j] = {
                    ...catalogueList[i].items[j],
                    isFavorite: false,
                    isFavoriteSuccessfully: true,
                  };
                }
              }
            }
          } else {
            for (let j = 0; j < catalogueList[i].subcategory.length; j += 1) {
              if (catalogueList?.[i]?.subcategory?.[j]?._id === item.parentSubCategoryId) {
                for (let k = 0; k < catalogueList[i].subcategory[j].items.length; k += 1) {
                  if (catalogueList?.[i].subcategory?.[j]?.items?.[k]?._id === item._id) {
                    if (item.isFavoriteSuccessfully) {
                      catalogueList[i].subcategory[j].items[k] = {
                        ...catalogueList[i].subcategory[j].items[k],
                        isFavorite: true,
                      };
                    } else {
                      catalogueList[i].subcategory[j].items[k] = {
                        ...catalogueList[i].subcategory[j].items[k],
                        isFavorite: false,
                        isFavoriteSuccessfully: true,
                      };
                    }
                  }
                }
              }
            }
          }
          break;
        }
      }
      return {
        ...state,
        catalogueList,
        isFavoriteSuccessfully,
      };
    }

    case ORDER_REDUX_CONSTANTS.REMOVE_FAVORITE: {
      const catalogueList = [...state.catalogueList];
      const { item, isFavoriteSuccessfully } = action;
      for (let i = 0; i < catalogueList.length; i += 1) {
        if (catalogueList?.[i]?._id === item.parentCategoryId) {
          if (catalogueList[i].items.length > 0) {
            for (let j = 0; j < catalogueList[i].items.length; j += 1) {
              if (catalogueList?.[i]?.items[j]?._id === item._id) {
                if (item.isFavoriteSuccessfully) {
                  catalogueList[i].items[j] = {
                    ...catalogueList[i].items[j],
                    isFavorite: false,
                  };
                } else {
                  catalogueList[i].items[j] = {
                    ...catalogueList[i].items[j],
                    isFavorite: true,
                    isFavoriteSuccessfully: true,
                  };
                }
              }
            }
          } else {
            for (let j = 0; j < catalogueList[i].subcategory.length; j += 1) {
              if (catalogueList?.[i]?.subcategory?.[j]?._id === item.parentSubCategoryId) {
                for (let k = 0; k < catalogueList[i].subcategory[j].items.length; k += 1) {
                  if (catalogueList?.[i].subcategory?.[j]?.items?.[k]?._id === item._id) {
                    if (item.isFavoriteSuccessfully) {
                      catalogueList[i].subcategory[j].items[k] = {
                        ...catalogueList[i].subcategory[j].items[k],
                        isFavorite: false,
                      };
                    } else {
                      catalogueList[i].subcategory[j].items[k] = {
                        ...catalogueList[i].subcategory[j].items[k],
                        isFavorite: true,
                        isFavoriteSuccessfully: true,
                      };
                    }
                  }
                }
              }
            }
          }
          break;
        }
      }
      return {
        ...state,
        catalogueList,
        isFavoriteSuccessfully,
      };
    }

    case ORDER_REDUX_CONSTANTS.ORDER_NOT_CONFIRMED: {
      const { isOrderConfirmed } = action;
      return {
        ...state,
        isOrderConfirmed,
      };
    }

    case ORDER_REDUX_CONSTANTS.CONFIRM_ORDER:
      return {
        ...state,
        orderList: action.data,
        isOrderConfirmed: true,
      };

    case ORDER_REDUX_CONSTANTS.RESET_ORDER_STATE:
      return initialOrder;

    case ORDER_REDUX_CONSTANTS.GET_TABLE_NUMBER_REQUEST:
      return {
        ...state,
      };

    case ORDER_REDUX_CONSTANTS.GET_TABLE_NUMBER_SUCCESS:
      return {
        ...state,
        tableNUmberList: action.data,
      };

    case ORDER_REDUX_CONSTANTS.GET_TABLE_NUMBER_FAIL:
      return {
        ...state,
      };

    default:
      return state;
  }
};
