import Order from '../../pages/order/Order';
import OrderSuccess from '../../pages/OrderSuccess/OrderSuccess';
import UserInformation from '../../pages/user-info/UserInfo';

export const ROUTES_CONSTANTS = [
  {
    path: '/',
    component: Order,
  },
  {
    path: '/bot',
    component: Order,
  },
  {
    path: '/user-info',
    component: UserInformation,
  },
  {
    path: '/order-success',
    component: OrderSuccess,
  },
];
