import ApiService from '../../../constants/ApiService';
import { ORDER_URLS } from '../../../constants/UrlConstants';

export const orderApiService = {
  getCatalogueList: () => ApiService.getData(ORDER_URLS.getCatalogueList),
  getPendingOrders: () => ApiService.getData(ORDER_URLS.getPendingOrderList),
  getTax: () => ApiService.getData(ORDER_URLS.getTax),
  changeOrderList: data => ApiService.putData(ORDER_URLS.getPendingOrderList, data),
  confirmOrderList: data => ApiService.postData(ORDER_URLS.confirmOrder, data),
  addFavoriteItem: data => ApiService.postData(ORDER_URLS.addOrDeleteFavorite, data),
  getTablenumber: () => ApiService.getData(ORDER_URLS.getTableNumber),
};
