import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { errorNotification } from '../Toast';

import { addItem, decreaseItem } from '../../pages/order/orderRedux/orderAction';
import Image from '../Image/Image';
import { euroConverter } from '../../utils/utils';
import { useQueryParams } from '../../hooks/GetQueryParamHook';

const Item = props => {
  const { item, itemClass, isSubcategory, ...restProps } = props;
  const { customerExists } = useQueryParams();

  const itemClassName = `item-container ${itemClass}`;
  const dispatch = useDispatch();
  const { orderList } = useSelector(({ order }) => order ?? {});

  const onAddQuantity = useCallback(
    async addedItem => {
      const finalOrderList = [...orderList];
      const existingItemIndex = finalOrderList.findIndex(
        existingOrder => existingOrder._id === item._id
      );

      if (existingItemIndex === -1) {
        finalOrderList.push({
          name: item?.name,
          price: item?.price,
          quantity: item?.quantity + 1,
          _id: item?._id,
          parentCategoryId: item?.parentCategoryId,
        });
      } else {
        finalOrderList[existingItemIndex] = item;
      }
      await dispatch(addItem(addedItem, finalOrderList, customerExists));
    },
    [orderList, item]
  );

  const onDecreaseItem = useCallback(
    async deletedItem => {
      if (deletedItem.quantity > 0) {
        const finalOrderList = [...orderList];
        const existingItemIndex = finalOrderList.findIndex(
          existingOrder => existingOrder._id === item._id
        );
        if (existingItemIndex !== -1 && item.quantity > 0) {
          finalOrderList[existingItemIndex] = item;
        }
        await dispatch(decreaseItem(deletedItem, finalOrderList, customerExists));
      } else {
        errorNotification('Selected item quantity is already zero');
      }
    },
    [orderList]
  );

  return (
    <>
      <div className={itemClassName} {...restProps}>
        <div className="item-image-container">
          <Image src={item?.fileUrl} className="item-image" />
        </div>
        <div className="item-detail">
          <div className="item-name">{item?.name}</div>
          <div className="item-price">{euroConverter(item?.price)}</div>
        </div>
        <div className="item-quantity-container">
          <div className="item-add-container">
            <span className="material-icons-round rm-border" onClick={() => onDecreaseItem(item)}>
              remove
            </span>
            <div className="item-quantity">{item?.quantity}</div>
            <span className="material-icons-round add-border" onClick={() => onAddQuantity(item)}>
              add
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

Item.propTypes = {
  item: PropTypes.object.isRequired,
  itemClass: PropTypes.string,
  isSubcategory: PropTypes.bool,
};

Item.defaultProps = {
  itemClass: '',
  isSubcategory: false,
};

export default Item;
