/* eslint-disable consistent-return */
import { orderApiService } from '../orderService/orderApiService';
import { ORDER_REDUX_CONSTANTS } from './orderReduxConstants';
import { errorNotification, successNotification } from '../../../components/Toast';

export const getCatalogue = () => {
  return async dispatch => {
    dispatch({
      type: ORDER_REDUX_CONSTANTS.REQUEST_CATALOGUE_LIST,
    });
    try {
      const response = await orderApiService.getCatalogueList();
      dispatch({
        type: ORDER_REDUX_CONSTANTS.GET_CATALOGUE,
        data: response.data.data,
      });
    } catch (e) {
      console.log(e);
      dispatch({
        type: ORDER_REDUX_CONSTANTS.FAIL_GET_CATALOGUE_LIST,
      });
    }
  };
};

export const getPendingOrderList = () => {
  return async dispatch => {
    try {
      dispatch({
        type: ORDER_REDUX_CONSTANTS.REQUEST_PENDING_ORDER_LIST,
      });
      const response = await orderApiService.getPendingOrders();
      if (response.status === 200) {
        dispatch({
          type: ORDER_REDUX_CONSTANTS.GET_PENDING_ORDER_LIST,
          data: response.data.data,
        });
        await dispatch(getCatalogue());
      }
    } catch (e) {
      dispatch({
        type: ORDER_REDUX_CONSTANTS.FAIL_PENDING_ORDER_LIST,
      });
    }
  };
};

export const getTax = () => {
  return async dispatch => {
    try {
      const response = await orderApiService.getTax();
      if (response.status === 200) {
        dispatch({
          type: ORDER_REDUX_CONSTANTS.GET_TAX,
          data: response.data.data,
        });
      }
    } catch (e) {
      /* error */
    }
  };
};

export const resetOrder = () => {
  return dispatch => {
    dispatch({
      type: ORDER_REDUX_CONSTANTS.RESET_ORDER_STATE,
    });
  };
};

export const addItem = (item, orderList, customerExists) => {
  return async dispatch => {
    try {
      dispatch({
        type: ORDER_REDUX_CONSTANTS.ADD_QUANTITY,
        item,
      });
      dispatch({
        type: ORDER_REDUX_CONSTANTS.DRAFT_ORDER_LIST,
        orderList,
      });
      if (customerExists !== 'false') await orderApiService.changeOrderList({ items: orderList });
    } catch (e) {
      /* error */
    }
  };
};

export const decreaseItem = (item, orderList, customerExists) => {
  return async dispatch => {
    try {
      dispatch({
        type: ORDER_REDUX_CONSTANTS.DECREASE_QUANTITY,
        item,
      });
      dispatch({
        type: ORDER_REDUX_CONSTANTS.DRAFT_ORDER_LIST,
        orderList,
      });
      if (customerExists !== 'false') await orderApiService.changeOrderList({ items: orderList });
    } catch (e) {
      /* error */
    }
  };
};

export const onAddFavoriteItem = item => {
  const data = {
    favoriteItem: item._id,
  };
  return async dispatch => {
    try {
      dispatch({
        type: ORDER_REDUX_CONSTANTS.ADD_FAVORITE,
        item: {
          ...item,
          isFavoriteSuccessfully: true,
        },
      });
      const response = await orderApiService.addFavoriteItem(data);
      if (response.status !== 200) {
        dispatch({
          type: ORDER_REDUX_CONSTANTS.ADD_FAVORITE,
          item: {
            ...item,
            isFavoriteSuccessfully: false,
          },
        });
      }
    } catch (e) {
      dispatch({
        type: ORDER_REDUX_CONSTANTS.ADD_FAVORITE,
        item: {
          ...item,
          isFavoriteSuccessfully: false,
        },
      });
    }
  };
};

export const onRemoveFavoriteItem = item => {
  return async dispatch => {
    try {
      dispatch({
        type: ORDER_REDUX_CONSTANTS.REMOVE_FAVORITE,
        item: {
          ...item,
          isFavoriteSuccessfully: true,
        },
      });
      const response = await orderApiService.deleteFavoriteItem(item._id);
      if (response.status !== 200) {
        dispatch({
          type: ORDER_REDUX_CONSTANTS.REMOVE_FAVORITE,
          item: {
            ...item,
            isFavoriteSuccessfully: false,
          },
        });
      }
    } catch (e) {
      dispatch({
        type: ORDER_REDUX_CONSTANTS.REMOVE_FAVORITE,
        item: {
          ...item,
          isFavoriteSuccessfully: false,
        },
      });
    }
  };
};

export const removeItemFromOrderList = (orderList, deletedItem) => {
  return async dispatch => {
    try {
      dispatch({
        type: ORDER_REDUX_CONSTANTS.UPDATE_ORDER_LIST,
        orderList,
        deletedItem,
      });
      await orderApiService.changeOrderList({ items: orderList });
    } catch (e) {
      /* error */
    }
  };
};

export const confirmOrder = (orderList, customerExists) => {
  return async dispatch => {
    try {
      dispatch({
        type: ORDER_REDUX_CONSTANTS.ORDER_NOT_CONFIRMED,
        isOrderConfirmed: false,
      });
      const response = await orderApiService.confirmOrderList(orderList);
      if (response.data.status === 'SUCCESS') {
        successNotification('Your order has been confirmed');
        dispatch({
          type: ORDER_REDUX_CONSTANTS.CONFIRM_ORDER,
          data: orderList,
          isOrderConfirmed: true,
        });
        if (customerExists !== 'false') {
          setInterval(
            () =>
              window.MessengerExtensions.requestCloseBrowser(
                function success() {},
                function error() {
                  // console.log(err);
                }
              ),
            1500
          );
        }
      }
      return response;
    } catch (e) {
      errorNotification(e?.response?.data?.message || 'Sorry, something went wrong, try again');
      dispatch({
        type: ORDER_REDUX_CONSTANTS.ORDER_NOT_CONFIRMED,
        isOrderConfirmed: true,
      });
    }
  };
};

export const getTable = () => {
  return async dispatch => {
    dispatch({
      type: ORDER_REDUX_CONSTANTS.GET_TABLE_NUMBER_REQUEST,
    });
    try {
      const response = await orderApiService.getTablenumber();
      dispatch({
        type: ORDER_REDUX_CONSTANTS.GET_TABLE_NUMBER_SUCCESS,
        data: response.data,
      });
    } catch (e) {
      dispatch({
        type: ORDER_REDUX_CONSTANTS.GET_TABLE_NUMBER_FAIL,
      });
    }
  };
};
