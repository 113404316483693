import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ROUTES_CONSTANTS } from './constants/RoutesConstants';

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        {ROUTES_CONSTANTS.map(({ path, component }) => {
          return <Route key={path} exact path={path} component={component} />;
        })}
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
