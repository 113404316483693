import React from 'react';

const PageNotFound = () => {
  return (
    <div className="page-not-found">
      <span className="material-icons">dangerous</span>
      <div>Page not found, please reload..</div>
    </div>
  );
};

export default PageNotFound;
