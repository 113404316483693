export const ORDER_REDUX_CONSTANTS = {
  REQUEST_CATALOGUE_LIST: 'REQUEST_CATALOGUE_LIST',
  GET_CATALOGUE: 'GET_CATALOGUE',
  FAIL_GET_CATALOGUE_LIST: 'FAIL_GET_CATALOGUE_LIST',
  REQUEST_PENDING_ORDER_LIST: 'REQUEST_PENDING_ORDER_LIST',
  GET_PENDING_ORDER_LIST: 'GET_PENDING_ORDER_LIST',
  FAIL_PENDING_ORDER_LIST: 'FAIL_PENDING_ORDER_LIST',
  GET_TAX: 'GET_TAX',
  ADD_QUANTITY: 'ADD_QUANTITY',
  DECREASE_QUANTITY: 'DECREASE_QUANTITY',
  DRAFT_ORDER_LIST: 'DRAFT_ORDER_LIST',
  UPDATE_ORDER_LIST: 'UPDATE_ORDER_LIST',
  CONFIRM_ORDER: 'CONFIRM_ORDER',
  ORDER_NOT_CONFIRMED: 'ORDER_NOT_CONFIRMED',
  ADD_FAVORITE: 'ADD_FAVORITE',
  REMOVE_FAVORITE: 'REMOVE_FAVORITE',
  RESET_ORDER_STATE: 'RESET_ORDER_STATE',
  GET_TABLE_NUMBER_REQUEST: 'GET_TABLE_NUMBER_REQUEST',
  GET_TABLE_NUMBER_SUCCESS: 'GET_TABLE_NUMBER_SUCCESS',
  GET_TABLE_NUMBER_FAIL: 'GET_TABLE_NUMBER_FAIL',
};
